export type Feature =
  | 'dark-theme'
  | 'ads-filters'
  | 'ad-strength'
  | 'ad-scheduling'
  | 'unspent-budget-cap'
  | 'acs-suggestions-field'
  | 'team-dashboard-filters'
  | 'spend-unspent-budget-after-end-date'
  | 'ad-pool-bulk-actions'
  | 'andorra';

export type FeatureMap = Record<Feature, boolean>;

// Order "matters"
export const FEATURE_FLAGS: FeatureMap = {
  // Public BETA
  // BETA
  'ads-filters': false,
  'unspent-budget-cap': false,
  'acs-suggestions-field': false,
  andorra: false,
  // ALPHA
  'dark-theme': false,
  'ad-strength': false,
  'ad-scheduling': false,
  'team-dashboard-filters': false,
  'spend-unspent-budget-after-end-date': false,
  'ad-pool-bulk-actions': false,
};

const ALPHA_FEATURES = new Set<Feature>([
  'dark-theme',
  'ad-strength',
  'ad-scheduling',
  'team-dashboard-filters',
  'spend-unspent-budget-after-end-date',
  'ad-pool-bulk-actions',
]);

export function isAlphaFeature(feature: Feature) {
  return ALPHA_FEATURES.has(feature);
}

const BETA_FEATURES = new Set<Feature>(['ads-filters', 'unspent-budget-cap', 'acs-suggestions-field', 'andorra']);

export function isBetaFeature(feature: Feature) {
  return BETA_FEATURES.has(feature);
}

const PUBLIC_BETA_FEATURES = new Set<Feature>([]);

export function isPublicBetaFeature(feature: Feature) {
  return PUBLIC_BETA_FEATURES.has(feature);
}
